import Moment from 'react-moment';
import moment from 'moment';

import './assets/log.scss';

const Log = ({log,products,setLog,setProducts})=>{
  const export_log = ()=>{
    let csvContent = "Vorgang,Datum,Summe,Produkt,Preis,Notiz\n"+log.map((entry,index) =>{
      let cols = [
        index,
        '"'+moment(entry.time).format("DD.MM.YYYY HH:mm:ss")+'"',
        '"'+new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entry.sum)+'"'
      ];
    
      return entry.items.map((prod,prodi)=>{
        return [...cols,['"'+prod.name+'"','"'+new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prod.price)+'"','"'+prod.note+'"']].join(',');
      }).join("\n");
    }).join("\n");


    const filename = 'export.csv'
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }else{
          window.location = URL.createObjectURL(blob);
        }
    }
  };

  const reset_log = ()=>{
    if(window.confirm("Sind Sie sicher? Alle Daten gehen verloren!")){
      localStorage.setItem("log",JSON.stringify([]));
      window.location.reload();
    }
  }

  const arrayColumn = (arr, n) => arr.map(x => x[n]);
  const getSum = ()=>{
    let prices = arrayColumn(log,'sum');
    return prices.reduce(function(pv, cv) { return pv + cv; }, 0);
  }

  return (
    <div className="log">
      <div className="log__list">
        {log.map((entry,index)=>(
          <div className="log__list__item" key={"log_"+index}>
            <Moment date={entry.time} format="DD.MM.YYYY HH:mm:ss" />
            <div>
              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entry.sum)}
            </div>
          </div>
        ))}
      </div>
      <div className="log__sum">
        <div>
          Gesamtsumme:<br/><span>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getSum())}</span>
        </div>
        {!!log.length&&(
          <>
            <button className="log__export" onClick={export_log}>Export</button>
            <button className="log__reset" onClick={reset_log}>Reset</button>
          </>
        )}
      </div>
      
    </div>
  );
}

export default Log;