import { useEffect,useState } from "react";

import Kasse from './Kasse';
import Log from './Log';
import Settings from './Settings';

import './assets/app.scss';

function App() {
  const [view,setView] = useState('kasse');
  const [style,setStyle] = useState({height:"100vh"});

  const [products,setProducts] = useState(localStorage.getItem("products")?JSON.parse(localStorage.getItem("products")):[]);
  const [log,setLog] = useState(localStorage.getItem("log")?JSON.parse(localStorage.getItem("log")):[]);

  useEffect(()=>{
    localStorage.setItem("log",JSON.stringify(log));
  },[log]);
  useEffect(()=>{
    localStorage.setItem("products",JSON.stringify(products));
  },[products]);

  useEffect(()=>{
    setStyle({height: document.getElementById("body").offsetHeight});
    window.addEventListener("resize", ()=>{
      setStyle({height: document.getElementById("body").offsetHeight});
    });
  },[]);

  return (
    <div className="app" id="app" style={style}>
      <div className="app__main">
        {view==='kasse' && (
          <Kasse log={log} products={products} setLog={setLog} setProducts={setProducts}/>
        )}
        {view==='log' && (
          <Log log={log} products={products} setLog={setLog} setProducts={setProducts}/>
        )}
        {view==='settings' && (
          <Settings log={log} products={products} setLog={setLog} setProducts={setProducts}/>
        )}
      </div>
      <div className="app__navigation">
        <button onClick={()=>setView('kasse')} className={"app__navigation__item"+(view==='kasse'?' app__navigation__item--active':'')}>
          Kasse
        </button>
        <button onClick={()=>setView('log')} className={"app__navigation__item"+(view==='log'?' app__navigation__item--active':'')}>
          Verlauf
        </button>
        <button onClick={()=>setView('settings')} className={"app__navigation__item"+(view==='settings'?' app__navigation__item--active':'')}>
          Einstellungen
        </button>
      </div>
    </div>
  );
}

export default App;
