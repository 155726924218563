import './assets/settings.scss';

const Settings = ({log,products,setLog,setProducts})=>{

  const addProduct = ()=>{
    let prod = [...products];
    prod.push({
      name: "Name",
      price: 1,
      color: '#FF0000'
    });
    setProducts(prod);
  }

  const updateProduct = (index,type,value)=>{
    let prod = [...products];
    prod[index][type] = value;
    setProducts(prod);
  }
  const removeProduct = (index)=>{
    let prod = [...products];
    prod.splice(index, 1);
    setProducts(prod);
  }

  const reset = ()=>{
    if(window.confirm("Sind Sie sicher? Alle Daten gehen verloren!")){
      localStorage.clear();
      window.location.reload();
    }
  }

  return (
    <div className="settings">
      {products.map((product,index)=>(
        <div className="settings__item" key={"settings_"+index}>
          <input type="text" className="settings__name" value={product.name} onChange={e=>updateProduct(index,'name',e.target.value)}/>
          <input type="number" className="settings__price" value={product.price} min={0.01} step={0.01} onChange={e=>updateProduct(index,'price',e.target.value?parseFloat(e.target.value):'')}/>
          <input type="color" className="settings__color" value={product.color} onChange={e=>updateProduct(index,'color',e.target.value)}/>
          <button className="settings__remove" onClick={()=>removeProduct(index)}>-</button>
        </div>
      ))}
      <div className="settings__item settings__item--empty">
        <button onClick={addProduct}>+</button>
      </div>
      <button onClick={reset} className="settings__reset">Hard Reset</button>
    </div>
  );
}

export default Settings;