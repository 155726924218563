import { useState } from "react";

import './assets/kasse.scss';

const Kasse = ({log,products,setLog,setProducts})=>{
  const [process,setProcess] = useState([]);

  const addToProcess = (index)=>{
    let pro = [...process];
    pro.push(products[index]);
    setProcess(pro);
  }

  const arrayColumn = (arr, n) => arr.map(x => x[n]);
  const getSum = ()=>{
    let prices = arrayColumn(process,'price');
    return prices.reduce(function(pv, cv) { return pv + cv; }, 0);
  }

  const addToLog = (extra)=>{
    let prod = process.map(x => {return {name:x.name,price:!!extra?0:x.price, note: !!extra?extra:''}});
    let l = [...log];
    l.push({time: new Date().getTime(),sum: !!extra?0:getSum(), items: prod});
    setLog(l);
    setProcess([]);
  }

  let width = 1;
  if(products.length >= 20){
    width = 5;
  }else if(products.length >= 12){
    width = 4;
  }else if(products.length > 4){
    width = 3;
  }else if(products.length > 1){
    width = 2;
  }

  return (
    <div className="kasse">
      <div className="kasse__products">
        {products.map((product,index)=>(
          <div className="kasse__products__item" key={"product_"+index} style={{'width':(100 / width)+'%','backgroundColor':product.color}} onClick={()=>addToProcess(index)}>
            <span>{product.name}</span>
          </div>
        ))}
      </div>
      <div className="kasse__bill">
        <div className="kasse__bill__items">
          {process.map((item,index)=>(
            <div className="kasse__bill__item" key={"bill_"+index}>
              <div>{item.name}</div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.price)}</div>
            </div>
          ))}
        </div>
        {!!process.length&&(
          <>
            <div className="kasse__bill__sum">
              Summe: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getSum())}
            </div>
            <div className="kasse__bill__options">
              <button className="kasse__bill__options__item" onClick={()=>addToLog(false)}>Bezahlt</button>
              <button className="kasse__bill__options__item kasse__bill__options__item--storno" onClick={()=>{setProcess([])}}>Storno</button>
            </div>
            <div className="kasse__bill__options">
              <button className="kasse__bill__options__item kasse__bill__options__item--extra" onClick={()=>addToLog('bon')}>Bon</button>
              <button className="kasse__bill__options__item kasse__bill__options__item--extra" onClick={()=>addToLog('helfer')}>Helfer</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Kasse;